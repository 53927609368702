<template>
	<div class="youtube-channel">
		<el-row class="function-area">
			<el-button type="primary" size="mini" @click="showAddDialog">添加频道</el-button>
		</el-row>

		<el-table :data="youTubeChannels" style="width: 100%">
			<el-table-column prop="channelName" label="频道名称" width="150"></el-table-column>
			<el-table-column prop="running" label="主页链接" width="100">
				<template slot-scope="scope">
					<el-link :href="scope.row['channelHomeUrl']" type="primary" target="_blank">主页链接</el-link>
				</template>
			</el-table-column>
			<el-table-column prop="running" label="是否有效" width="100">
				<template slot-scope="scope">
					<el-tag v-if="scope.row['isValid']" type="success">有效</el-tag>
					<el-tag v-else type="warning">无效</el-tag>
				</template>
			</el-table-column>
			<el-table-column prop="privacyStatus" label="公开状态" width="120"></el-table-column>
			<el-table-column prop="xiguaChannelName" label="西瓜频道" width="150"></el-table-column>
			<el-table-column prop="clientSecretName" label="绑定密钥" width="120"></el-table-column>
			<el-table-column prop="createTime" label="创建时间" width="180"></el-table-column>
			<el-table-column prop="updateTime" label="更新时间" width="180"></el-table-column>
			<el-table-column label="操作" width="150">
				<template slot-scope="scope">
					<el-button @click.native.prevent="showEditDialog(scope.$index)" type="text" size="small">修改</el-button>
					<el-button @click.native.prevent="showTokenInfo(scope.$index)" type="text" size="small">查看token</el-button>
				</template>
			</el-table-column>
		</el-table>

		<el-dialog :title="addOrEdit === 'ADD' ? '添加频道' : '修改频道'" :visible.sync="addOrEditDialog" width="70%" top="5vh">
			<el-form :model="addOrEditFormData" :rules="addOrEditFormRules" ref="addForm" label-width="110px" size="mini">
				<el-form-item label="频道名称" prop="channelName">
					<el-input v-model="addOrEditFormData.channelName" placeholder="请输入任务名称"></el-input>
				</el-form-item>
				<el-form-item label="主页链接" prop="channelHomeUrl">
					<el-input v-model="addOrEditFormData.channelHomeUrl" placeholder="请输入主页链接"></el-input>
				</el-form-item>
				<el-form-item label="西瓜频道" prop="xiguaChannelId">
					<el-select v-model="addOrEditFormData.xiguaChannelId" placeholder="请选择频道">
						<el-option :key="channel.id" :label="channel.channelName" :value="channel.id" v-for="channel in xiguaChannels"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="公开状态" prop="privacyStatus">
					<el-select v-model="addOrEditFormData.privacyStatus" placeholder="请选择公开状态">
						<el-option label="private" value="private"></el-option>
						<el-option label="public" value="public"></el-option>
						<el-option label="unlisted" value="unlisted"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="视频描述" prop="description">
					<el-input type="textarea" v-model="addOrEditFormData.description" placeholder="请输入默认描述，#代表插入视频标题"></el-input>
				</el-form-item>
				<el-form-item label="视频标签" prop="tags">
					<el-input type="textarea" v-model="addOrEditFormData.tags" placeholder="请输入视频标签"></el-input>
				</el-form-item>
				<el-form-item label="是否有效" prop="isValid">
					<el-switch v-model="addOrEditFormData.isValid" :active-value="1" :inactive-value="0"></el-switch>
				</el-form-item>
				<el-form-item label="备注" prop="note">
					<el-input v-model="addOrEditFormData.note"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="toAddOrEditTask('addForm')" :loading="addOrEditLoading">确 定</el-button>
					<el-button @click="toResetForm">取 消</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-dialog title="token信息" :visible.sync="tokenDialog" width="80%">
			<pre>{{ JSON.parse(currentTokenInfo) }}</pre>
			<div slot="footer" class="dialog-footer">
				<el-button @click="tokenDialog = false" size="small">关 闭</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import {getAllXiGuaChannels, getAllYouTubeChannels, addNewYouTubeChannel, updateYouTubeChannel} from "@/api/channel";
import {getAllSecrets} from "@/api/client_secret";

export default {
	name: "YouTubeChannelManage",
	data() {
		return {
			xiguaChannels: [],
			youTubeChannels: [],
			clientSecrets: [],

			addOrEdit: "",
			addOrEditFormData: {
				id: null, xiguaChannelId: null, channelName: null, channelHomeUrl: null,
				description: "#", tags: null, privacyStatus: null, categoryId: null, isValid: false, note: null
			},
			addOrEditFormRules: {
				xiguaChannelId: [{required: true, message: '请选择西瓜频道', trigger: 'change'}],
				channelName: [
					{required: true, message: '请输入频道名称', trigger: 'blur'},
					{min: 2, max: 15, message: '长度在2到15个字符', trigger: 'blur'}
				],
				channelHomeUrl: [{required: true, message: '请输入频道主页', trigger: 'blur'}],
				description: [{required: true, message: '请输入默认描述，#代表插入视频标题', trigger: 'blur'}],
				privacyStatus: [{required: true, message: '请选择公开状态', trigger: 'change'}],
			},
			addOrEditDialog: false,
			addOrEditLoading: false,

			tokenDialog: false,
			currentTokenInfo: null
		}
	},
	created() {
		this.initData();
	},
	methods: {
		async initData() {
			// 初始化数据
			const loading = this.$loading({lock: true, text: '加载中', spinner: 'el-icon-loading', background: 'rgba(243,239,239,0.7)'});
			const ret1 = await getAllXiGuaChannels().catch((error) => {
				loading.close();
				return this.$message.error('获取西瓜频道列表失败：' + error);
			});
			if (!ret1['success']) {
				loading.close();
				return this.$message.error('获取西瓜频道列表失败：' + ret1.data);
			}
			this.xiguaChannels = ret1.data;

			const ret2 = await getAllSecrets().catch((error) => {
				this.$message.error('获取所有Secrets：' + error);
			});
			if (!ret2['success']) {
				loading.close();
				return this.$message.error('获取所有Secrets列表失败：' + ret2.data);
			}
			this.clientSecrets = ret2.data;

			const ret3 = await getAllYouTubeChannels().catch((error) => {
				loading.close();
				return this.$message.error('获取Youtube频道列表失败：' + error);
			});
			if (!ret3['success']) {
				loading.close();
				return this.$message.error('获取Youtube频道列表失败：' + ret2.data);
			}
			const ytData = ret3.data;
			for (let i = 0; i < ytData.length; i++) {
				ytData[i].xiguaChannelName = this.xiguaChannels.find(item => item.id === ytData[i].xiguaChannelId).channelName;
				if (ytData[i].clientSecretId) {
					ytData[i].clientSecretName = this.clientSecrets.find(item => item.id === ytData[i].clientSecretId).secretName;
				} else {
					ytData[i].clientSecretName = null;
				}
			}
			this.youTubeChannels = ytData;
			loading.close();
		},

		showAddDialog() {
			this.addOrEdit = "ADD";
			this.addOrEditDialog = true;
		},
		showEditDialog(index) {
			this.addOrEdit = "EDIT";
			this.addOrEditDialog = true;
			this.addOrEditFormData = JSON.parse(JSON.stringify(this.youTubeChannels[index]));
		},
		toAddOrEditTask(formName) {
			this.$refs[formName].validate((valid) => {
				if (!valid) return false;

				this.addOrEditLoading = true;
				if (this.addOrEdit === 'ADD') {
					addNewYouTubeChannel(this.addOrEditFormData).then(response => {
						this.addOrEditLoading = false;
						if (!response['success']) return this.$message.error('添加频道失败：' + response.data);
						this.$message.success('添加频道成功');
						this.initData();
						this.toResetForm();
					}).catch((error) => {
						this.addOrEditLoading = false;
						this.$message.error('添加频道失败：' + error);
					});
				} else {
					updateYouTubeChannel(this.addOrEditFormData).then(response => {
						this.addOrEditLoading = false;
						if (!response['success']) return this.$message.error('修改频道失败：' + response.data);
						this.$message.success('修改频道成功');
						this.initData();
						this.toResetForm();
					}).catch((error) => {
						this.addOrEditLoading = false;
						this.$message.error('修改频道失败：' + error);
					});
				}
			});
		},
		toResetForm() {
			// 重置form
			this.addOrEditFormData = {
				id: null, xiguaChannelId: null, channelName: null, channelHomeUrl: null,
				description: "#", tags: null, privacyStatus: null, categoryId: null, isValid: false, note: null
			};
			this.addOrEditDialog = false;
		},
		showTokenInfo(index) {
			// 显示token详情
			this.tokenDialog = true;
			this.currentTokenInfo = this.youTubeChannels[index]['oauthToken'];
		}
	}
}
</script>

<style lang="less" scoped>
.youtube-channel {
	margin: 10px;

	.function-area {
		margin: 10px 0;
	}
}
</style>